import React from 'react';
// import PropTypes from 'prop-types'
import Layout from '../components/layout';

const FreshOrder = () => {
  return (
    <Layout className='flex items-center justify-center'>
      <div className='mx-auto text-center'>
        <h2 className='text-2xl text-red-600'>Регистрация в сервисе 1С:Фрэш</h2>
        <p className='text-lg text-blue-600'>30 дней бесплатно</p>
        <iframe
          width='100%'
          height='378px'
          src='https://1cfresh.com/a/httpextreg/hs/ExternalRegistration/iframe?promouser=extreg-227776-000000000000000001&promo=2676'
          seamless
        ></iframe>
      </div>
    </Layout>
  );
};

export default FreshOrder;
